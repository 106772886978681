import {
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import classNames from "classnames";
import BoxTextAbout from "../../components/BoxTextAbout";
import BoxPersonalization from "../../components/BoxPersonalization";
import RecordBox from "../../components/RecordBox/RecordBox";
import PrintingModal from "../../components/PrintingModal";
import BoxSharing from "../../components/BoxSharing";
import BoxExport from "../../components/BoxExport";
import BoxTextTool from "../../components/BoxTextTool";
import BoxSatelliteBrand from "../../components/BoxSatelliteBrand";
import personalizationIcon from "../../assets/personalization-icon.svg";
import randomIcon from "../../assets/random.svg";
import levelsIcon from "../../assets/levels-icon.svg";
import BoxManual from "../../components/BoxManual";
import {
  FeaturesContext,
  ParametersContext,
  PlayerContext,
} from "../../contexts";
import Logo from "../../components/Logo";
import { COLOR_THEMES } from "../../utils/colors";
import { paramsFromSound } from "../../utils/audioAnalysis";
import styles from "./AnimatedLogoDesktop.module.css";
import BoxSave from "../../components/BoxSave";
import { CANONICAL_PARAMS } from "../../utils/params";
import { useElementSize } from "usehooks-ts";
import { useLoadRandomLogo } from "../../hooks/logo";
import UploadModal from "../../components/UploadModal/UploadModal";
import findIndex from "lodash/findIndex";
import CheckBrowser from "../../components/CheckBrowser";
import SendModal from "../../components/SendModal/SendModal";
import { splitEtichetta } from "../../utils/text";

export default function AppDesktop({ mode = "basic", imperativeLogo }) {
  const [boxPersonalization, setBoxPersonalization] = useState(false);
  const [boxSharing, setBoxSharing] = useState(false);
  const [boxSatelliteBrand, setBoxSatelliteBrand] = useState(false);
  const [boxManual, setBoxManual] = useState(false);
  const [boxExport, setBoxExport] = useState(false);
  const [boxUpload, setBoxUpload] = useState(false);
  const [boxSend, setBoxSend] = useState(false);
  // TODO: Check boxes...
  const [boxSave, setBoxSave] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const [bodyRef, { width: bodyWidth }] = useElementSize();
  const loadRandomLogo = useLoadRandomLogo();

  const features = useContext(FeaturesContext);
  const {
    params,
    setParams,
    maxValue,
    setMaxValue,
    maxDelta,
    setMaxDelta,
    colorTheme,
    setColorTheme,
    paramsStart,
    addParamsToHistory,
    paramsRef,
    etichetta,
    setEtichetta,
    years,
    setYears,
    paramsHistory,
  } = useContext(ParametersContext);

  const { audioBlob, isRecording, isPlaying } = useContext(PlayerContext);
  const logoRef = useRef();

  useEffect(() => {
    // In imperative mode sync params when no live
    // animation in place...
    if (imperativeLogo) {
      if (!isPlaying && !isRecording) {
        logoRef.current.setParams(params);
      }
    }
  }, [
    isPlaying,
    isRecording,
    params,
    colorTheme,
    imperativeLogo,
    maxValue,
    maxDelta,
  ]);
  // In imperative avoid triggere rendering of <Logo />
  // while live animation in place...
  const logoParams = !imperativeLogo
    ? params
    : isPlaying || isRecording
    ? {}
    : params;

  const transformParametersFromSound = useCallback(
    (nextSample, nextSoundData, soundStats, firstTick) => {
      const nextParams = paramsFromSound(
        nextSoundData,
        soundStats,
        paramsRef.current,
        maxValue,
        paramsStart.current
      );
      if (imperativeLogo) {
        logoRef.current.setParams(nextParams, paramsRef.current);
      }
      setParams(nextParams);
      if (!features.micAlwaysActive) {
        addParamsToHistory({ ...nextParams, timestamp: nextSample.timestamp });
      }
    },
    [
      paramsRef,
      maxValue,
      paramsStart,
      imperativeLogo,
      setParams,
      features.micAlwaysActive,
      addParamsToHistory,
    ]
  );

  const transformParametersFromSoundPlay = useCallback(
    (nextSample, nextSoundData, soundStats, firstTick, forceStop) => {
      if (firstTick) {
        if (imperativeLogo) {
          logoRef.current.setParams(CANONICAL_PARAMS, paramsRef.current);
        }
        setParams({ ...CANONICAL_PARAMS });
        return;
      }
      // console.log("paramsHistory", paramsHistory, nextSample)
      // console.log("sounda", soundStats, nextSoundData)
      const playTime =
        nextSample.timestamp.getTime() -
        soundStats.firstSample.timestamp.getTime();
      // console.log("playTime", playTime)
      const paramsHistoryStart = paramsHistory.current[0].timestamp.getTime();
      const nextParamsTsIndex = findIndex(
        paramsHistory.current,
        (p) => p.timestamp.getTime() - paramsHistoryStart >= playTime
      );
      const nextParamsTs = paramsHistory.current[nextParamsTsIndex];
      // console.log("vv", nextParamsTsIndex, paramsHistory.current.length)

      // const nextParams = paramsFromSound(
      //   nextSoundData,
      //   soundStats,
      //   paramsRef.current,
      //   maxValue,
      //   paramsStart.current
      // );
      // console.log("nextParams", nextParams)
      // console.log("nextParamsTs", nextParamsTs)

      if (nextParamsTs) {
        if (imperativeLogo) {
          logoRef.current.setParams(nextParamsTs, paramsRef.current);
        }
        setParams(nextParamsTs);
      } else {
        console.warn("Missing params ... force stop ...:", {
          nextSample,
          paramsHistory: paramsHistory.current,
        });
        forceStop();
      }
    },

    [paramsRef, imperativeLogo, setParams, paramsHistory]
  );

  const playStartCallback = useCallback(() => {
    setParams({ ...CANONICAL_PARAMS });
  }, [setParams]);

  // Quando il box satellite è aperto e e age range è 0-18
  let deEtichetta = etichetta;
  if (colorTheme === "0-18" && (boxSatelliteBrand || !boxPersonalization)) {
    deEtichetta = "";
  }

  const [etichettaPrimaRiga, etichettaSecondaRiga] = useMemo(
    () => splitEtichetta(deEtichetta),
    [deEtichetta]
  );

  const logoSize = useMemo(() => {
    return bodyWidth > 1640 ? 800 : 655;
  }, [bodyWidth]);

  return (
    <div className={styles.BodyDesktop} ref={bodyRef}>
      <CheckBrowser />
      <div className={styles.ContainerDesktop}>
        <div className={styles.Left}>
          <div className={styles.BoxText}>
            {mode === "basic" && features.showDomanda && <BoxTextAbout />}
            {(mode === "tool" || mode === "pro") && <BoxTextTool type={mode} />}
          </div>
        </div>
        <div
          className={classNames(styles.Center, {
            [styles.LogoCenterNoDomanda]: !features.showDomanda,
          })}
        >
          <Logo
            ref={logoRef}
            {...logoParams}
            width={logoSize}
            height={logoSize}
            maxValue={maxValue}
            maschera0Color={COLOR_THEMES[colorTheme].maschera0Color}
            mascheraPuntoColor={COLOR_THEMES[colorTheme].mascheraPuntoColor}
            maschera8Color={COLOR_THEMES[colorTheme].maschera8Color}
            etichettaPrimaRiga={etichettaPrimaRiga}
            etichettaSecondaRiga={etichettaSecondaRiga}
            etichettaColor={COLOR_THEMES[colorTheme].etichettaColor}
            etichettaBackgroundColor={
              COLOR_THEMES[colorTheme].etichettaBackgroundColor
            }
            etichettaStrokeColor={
              COLOR_THEMES[colorTheme].etichettaStrokeColor || "none"
            }
            etichettaFontWeight={
              COLOR_THEMES[colorTheme].etichettaFontWeight || "700"
            }
            imperative={imperativeLogo}
          />
        </div>
        <div className={styles.Right}>
          <div className="w-100 d-flex align-items-center justify-content-end">
            <div className="d-flex flex-column align-items-end">
              {(mode === "tool" || mode === "pro") && (
                <>
                  <div className="d-flex flex-column align-items-center">
                    <div
                      className={`${styles.Button} mb-3`}
                      onClick={() => setBoxSatelliteBrand(true)}
                    >
                      <div className={styles.TextButton}>
                        genera marchio satellite
                      </div>
                      <img src={personalizationIcon} alt="Personalizza" />
                    </div>
                    <div className="mb-3 w-100 d-flex justify-content-end">
                      <div
                        style={{
                          width: 24,
                          marginRight: 8,
                          background: "black",
                          height: 1,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={`${styles.Button} mb-3`}
                    onClick={() => loadRandomLogo()}
                  >
                    <div className={styles.TextButton}>
                      genera randomicamente
                    </div>
                    <img src={randomIcon} alt="Random" />
                  </div>
                  {mode === "pro" && (
                    <div
                      className={`${styles.Button} mb-3`}
                      onClick={() => setBoxManual(true)}
                    >
                      <div className={styles.TextButton}>
                        gestisci manualmente
                      </div>
                      <img src={levelsIcon} alt="Levels" />
                    </div>
                  )}
                </>
              )}
              <RecordBox
                type={mode}
                dataCallback={transformParametersFromSound}
                replayDataCallback={transformParametersFromSoundPlay}
                // playStartCallback={playStartCallback}
              />
            </div>
            {mode === "basic" && Boolean(audioBlob) && !isRecording && (
              <div className="position-absolute links animated-logo-links">
                {features.print && (
                  <div
                    className="pointer me-4"
                    onClick={() => setIsPrinting(true)}
                  >
                    Stampa
                  </div>
                )}
                {features.customization && (
                  <div
                    className="pointer me-4"
                    onClick={() => setBoxPersonalization(true)}
                  >
                    Personalizza
                  </div>
                )}
                {features.download && (
                  <div className="pointer" onClick={() => setBoxSave(true)}>
                    Scarica
                  </div>
                )}
                {features.send && (
                  <div
                    className="ms-4 pointer"
                    onClick={() => setBoxSend(true)}
                  >
                    Invia
                  </div>
                )}
              </div>
            )}
            {(mode === "tool" || mode === "pro") && (
              <div className="position-absolute links">
                <div className="pointer" onClick={() => setBoxExport(true)}>
                  Scarica versioni
                </div>
                {mode === "pro" && (
                  <div
                    className="pointer ms-4"
                    onClick={() => setBoxUpload(true)}
                  >
                    Salva in archivio
                  </div>
                )}
              </div>
            )}
          </div>
          <BoxSave
            isOpen={boxSave}
            etichettaPrimaRiga={etichettaPrimaRiga}
            etichettaSecondaRiga={etichettaSecondaRiga}
            onConfirm={() => {
              setBoxSave(!boxSave);
            }}
            isPro={mode === "pro"}
            toggleBox={() => setBoxSave(!boxSave)}
          />
          <BoxPersonalization
            isOpen={boxPersonalization}
            name={etichetta || ""}
            setName={setEtichetta}
            years={years}
            setYears={setYears}
            onConfirm={(name, years) => {
              setBoxPersonalization(!boxPersonalization);
            }}
            isPro={mode === "pro"}
            toggleBox={() => setBoxPersonalization(!boxPersonalization)}
          />
          <BoxSharing
            isOpen={boxSharing}
            onConfirm={() => {
              setBoxSharing(!boxSharing);
            }}
            isPro={mode === "pro"}
            toggleBox={() => setBoxSharing(!boxSharing)}
          />
          <BoxSatelliteBrand
            isOpen={boxSatelliteBrand}
            name={etichetta}
            setName={setEtichetta}
            ageRange={colorTheme}
            setAgeRange={setColorTheme}
            isPro={mode === "pro"}
            onConfirm={(ageRange, labelText) => {
              setColorTheme(ageRange);
              setBoxSatelliteBrand(!boxSatelliteBrand);
            }}
            toggleBox={() => setBoxSatelliteBrand(!boxSatelliteBrand)}
          />
          <BoxManual
            isOpen={boxManual}
            params={params}
            setParams={setParams}
            setMaxDelta={setMaxDelta}
            maxDelta={maxDelta}
            maxValue={maxValue}
            isPro={mode === "pro"}
            setMaxValue={setMaxValue}
            onConfirm={() => setBoxManual(false)}
            toggleBox={() => setBoxManual(!boxManual)}
          />
          <BoxExport
            isOpen={boxExport}
            isPro={mode === "pro"}
            etichettaPrimaRiga={etichettaPrimaRiga}
            etichettaSecondaRiga={etichettaSecondaRiga}
            onConfirm={() => {
              setBoxExport(!boxExport);
            }}
            toggleBox={() => setBoxExport(!boxExport)}
          />
          <PrintingModal
            isOpen={isPrinting}
            toggle={() => setIsPrinting(!isPrinting)}
          />
          <UploadModal
            isOpen={boxUpload}
            onConfirm={() => setBoxUpload(!boxUpload)}
            toggle={() => setBoxUpload(!boxUpload)}
          />
          <SendModal
            isOpen={boxSend}
            onConfirm={() => setBoxSend(!boxSend)}
            toggle={() => setBoxSend(!boxSend)}
          />
        </div>
      </div>
    </div>
  );
}
