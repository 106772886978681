import { useCallback, useMemo, useRef, useState } from "react";
import {
  DEFAULT_FEATURES,
  FeaturesContext,
  ParametersContext,
  PlayerContext,
} from "../contexts";
import { useIsMobileScreen } from "../hooks/screen";
import AnimatedLogoDesktop from "./AnimatedLogoDesktop";
import AnimatedLogoMobile from "./AnimatedLogoMobile";
import { CANONICAL_PARAMS } from "../utils/params";

const IMPERATIVE_LOGO = true;

function AnimatedLogoPlatform({ mode }) {
  const isMobile = useIsMobileScreen();
  if (isMobile === null) {
    return null;
  }
  return isMobile ? (
    <AnimatedLogoMobile imperativeLogo={IMPERATIVE_LOGO} mode={mode} />
  ) : (
    <AnimatedLogoDesktop imperativeLogo={IMPERATIVE_LOGO} mode={mode} />
  );
}

export default function AnimatedLogo({
  mode = "basic",
  initialColorTheme = "0-18",
  initialEtichetta = "",
  features = DEFAULT_FEATURES,
  initialParams = CANONICAL_PARAMS,
  initialMaxValue = 30,
  initialMaxDelta = 29,
  initialParamsHistory = [],
}) {
  const [maxValue, _setMaxValue] = useState(initialMaxValue);
  const maxValueRef = useRef(maxValue);
  const setMaxValue = useCallback((arg) => {
    _setMaxValue(parseInt(arg));
    maxValueRef.current = parseInt(arg);
  }, []);

  const [maxDelta, _setMaxDelta] = useState(initialMaxDelta);
  const maxDeltaRef = useRef(maxDelta);
  const setMaxDelta = useCallback((arg) => {
    _setMaxDelta(parseInt(arg));
    maxDeltaRef.current = parseInt(arg);
  }, []);

  const [isPlaying, _setIsPlaying] = useState(false);
  const isPlayingRef = useRef(isPlaying);
  const setIsPlaying = useCallback((arg) => {
    _setIsPlaying(arg);
    isPlayingRef.current = arg;
    // paramsStart.current = {...paramsRef.current}
  }, []);

  const [isRecording, _setIsRecording] = useState(false);
  const isRecordingRef = useRef(isRecording);
  const setIsRecording = useCallback((arg) => {
    _setIsRecording(arg);
    isRecordingRef.current = arg;
  }, []);

  const [params, _setParams] = useState(initialParams);
  const paramsRef = useRef(params);
  const paramsStart = useRef({ ...params });

  const setParams = useCallback((arg) => {
    _setParams(arg);
    paramsRef.current = arg;
    if (!isPlayingRef.current) {
      paramsStart.current = arg;
    }
  }, []);

  const [etichetta, setEtichetta] = useState(initialEtichetta);

  const [audioBlob, setAudioBlob] = useState(null);
  const [audioSamples, setAudioSamples] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const [colorTheme, setColorTheme] = useState(initialColorTheme);
  const [years, _setYears] = useState(null);

  const setYears = useCallback((arg) => {
    const y = arg;
    if (y === null) {
      setColorTheme("0-18");
    } else if (y <= 6) {
      setColorTheme("0-6");
    } else if (y > 6 && y < 14) {
      setColorTheme("6-14");
    } else {
      setColorTheme("14-18");
    }
    _setYears(arg === null ? null : parseInt(arg));
  }, []);

  // const [paramsHistory, _setParamsHistory] = useState([]);
  const paramsHistory = useRef(initialParamsHistory);
  const setParamsHistory = useCallback((arg) => {
    paramsHistory.current = arg;
  }, []);

  const addParamsToHistory = useCallback((arg) => {
    paramsHistory.current.push(arg);
  }, []);

  const [videoColorTheme, setVideoColorTheme] = useState(colorTheme);
  const [videoEtichetta, setVideoEtichetta] = useState(etichetta);
  const snapshotVideoParams = useCallback(() => {
    setVideoColorTheme(colorTheme);
    setVideoEtichetta(etichetta);
  }, [colorTheme, etichetta]);

  const isVideoInSyncWithLogo = useMemo(() => {
    return videoColorTheme === colorTheme && videoEtichetta === etichetta;
  }, [colorTheme, etichetta, videoColorTheme, videoEtichetta]);

  return (
    <FeaturesContext.Provider value={features}>
      <ParametersContext.Provider
        value={{
          maxValue,
          setMaxValue,
          maxDelta,
          setMaxDelta,
          params,
          setParams,
          paramsStart,
          colorTheme,
          setColorTheme,
          paramsHistory,
          addParamsToHistory,
          setParamsHistory,
          paramsRef,
          etichetta,
          setEtichetta,
          years,
          setYears,
          videoEtichetta,
          videoColorTheme,
          snapshotVideoParams,
          isVideoInSyncWithLogo,
        }}
      >
        <PlayerContext.Provider
          value={{
            isPlaying,
            setIsPlaying,
            isRecording,
            setIsRecording,
            audioBlob,
            setAudioBlob,
            audioSamples,
            setAudioSamples,
            currentTime,
            setCurrentTime,
            totalTime,
            setTotalTime,
          }}
        >
          <AnimatedLogoPlatform mode={mode} />
        </PlayerContext.Provider>
      </ParametersContext.Provider>
    </FeaturesContext.Provider>
  );
}
